/*Button Styles
------------------------------------*/
.btn {
   box-shadow: none;
   border-radius: 0;
}
.btn-u {
   border: 0;
   color: #fff;
   font-size: 14px;
   cursor: pointer;
   font-weight: 400;
   padding: 6px 13px;
   position: relative;
   background: #72c02c;
   white-space: nowrap;
   display: inline-block;
   text-decoration: none;
}
.btn-u:hover {
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.btn-u.btn-block {
  text-align: center;
}

a.btn-u {
   /*padding: 4px 13px;*/
   /*vertical-align: middle;*/
}

.btn-u-sm,
a.btn-u-sm {
   padding: 3px 12px;
}

.btn-u-lg,
a.btn-u-lg {
   font-size: 18px;
   padding: 10px 25px;
}

.btn-u-xs,
a.btn-u-xs {
   font-size: 12px;
   padding: 2px 12px;   
   line-height: 18px;
}

/*Button Groups*/
.btn-group .dropdown-menu > li > a {
   padding: 3px 13px;
}

.btn-group > .btn-u, 
.btn-group-vertical > .btn-u {
   float: left;
   position: relative;
}

.btn-group > .btn-u:first-child {
   margin-left: 0;
}

/*For FF Only*/
@-moz-document url-prefix() { 
   .footer-subsribe .btn-u {
      padding-bottom: 4px;
   }
}
@media (max-width: 768px) { 
   @-moz-document url-prefix() { 
      .btn-u {
         padding-bottom: 6px;
      }
   }
}

/*Buttons Color*/
.btn-u:hover, 
.btn-u:focus, 
.btn-u:active, 
.btn-u.active, 
.open .dropdown-toggle.btn-u {
   background: #5fb611;
}
.btn-u-split.dropdown-toggle {
   border-left: solid 1px #5fb611;
}

.btn-u.btn-u-blue {
   background: #3498db;
}
.btn-u.btn-u-blue:hover, 
.btn-u.btn-u-blue:focus, 
.btn-u.btn-u-blue:active, 
.btn-u.btn-u-blue.active, 
.open .dropdown-toggle.btn-u.btn-u-blue {
   background: #2980b9;
}
.btn-u.btn-u-split-blue.dropdown-toggle {
   border-left: solid 1px #2980b9;
}

.btn-u.btn-u-red {
   background: #e74c3c;
}
.btn-u.btn-u-red:hover, 
.btn-u.btn-u-red:focus, 
.btn-u.btn-u-red:active, 
.btn-u.btn-u-red.active, 
.open .dropdown-toggle.btn-u.btn-u-red {
   background: #c0392b;
}
.btn-u.btn-u-split-red.dropdown-toggle {
   border-left: solid 1px #c0392b;
}

.btn-u.btn-u-orange {
   background: #e67e22;
}
.btn-u.btn-u-orange:hover, 
.btn-u.btn-u-orange:focus, 
.btn-u.btn-u-orange:active, 
.btn-u.btn-u-orange.active, 
.open .dropdown-toggle.btn-u.btn-u-orange {
   background: #d35400;
}
.btn-u.btn-u-split-orange.dropdown-toggle {
   border-left: solid 1px #d35400;
}

.btn-u.btn-u-sea {
   background: #1abc9c;
}
.btn-u.btn-u-sea:hover, 
.btn-u.btn-u-sea:focus, 
.btn-u.btn-u-sea:active, 
.btn-u.btn-u-sea.active, 
.open .dropdown-toggle.btn-u.btn-u-sea {
   background: #16a085;
}
.btn-u.btn-u-split-sea.dropdown-toggle {
   border-left: solid 1px #16a085;
}

.btn-u.btn-u-green {
   background: #2ecc71;
}
.btn-u.btn-u-green:hover, 
.btn-u.btn-u-green:focus, 
.btn-u.btn-u-green:active, 
.btn-u.btn-u-green.active, 
.open .dropdown-toggle.btn-u.btn-u-green {
   background: #27ae60;
}
.btn-u.btn-u-split-green.dropdown-toggle {
   border-left: solid 1px #27ae60;
}

.btn-u.btn-u-yellow {
   background: #f1c40f;
}
.btn-u.btn-u-yellow:hover, 
.btn-u.btn-u-yellow:focus, 
.btn-u.btn-u-yellow:active, 
.btn-u.btn-u-yellow.active, 
.open .dropdown-toggle.btn-u.btn-u-yellow {
   background: #f39c12;
}
.btn-u.btn-u-split-yellow.dropdown-toggle {
   border-left: solid 1px #f39c12;
}

.btn-u.btn-u-default {
   background: #95a5a6;
}
.btn-u.btn-u-default:hover, 
.btn-u.btn-u-default:focus, 
.btn-u.btn-u-default:active, 
.btn-u.btn-u-default.active, 
.open .dropdown-toggle.btn-u.btn-u-default {
   background: #7f8c8d;
}
.btn-u.btn-u-split-default.dropdown-toggle {
   border-left: solid 1px #7f8c8d;
}

.btn-u.btn-u-purple {
   background: #9b6bcc;
}
.btn-u.btn-u-purple:hover, 
.btn-u.btn-u-purple:focus, 
.btn-u.btn-u-purple:active, 
.btn-u.btn-u-purple.active, 
.open .dropdown-toggle.btn-u.btn-u-purple {
   background: #814fb5;
}
.btn-u.btn-u-split-purple.dropdown-toggle {
   border-left: solid 1px #814fb5;
}

.btn-u.btn-u-aqua {
   background: #27d7e7;
}
.btn-u.btn-u-aqua:hover, 
.btn-u.btn-u-aqua:focus, 
.btn-u.btn-u-aqua:active, 
.btn-u.btn-u-aqua.active, 
.open .dropdown-toggle.btn-u.btn-u-aqua {
   background: #26bac8;
}
.btn-u.btn-u-split-aqua.dropdown-toggle {
   border-left: solid 1px #26bac8;
}

.btn-u.btn-u-brown {
   background: #9c8061;
}
.btn-u.btn-u-brown:hover, 
.btn-u.btn-u-brown:focus, 
.btn-u.btn-u-brown:active, 
.btn-u.btn-u-brown.active, 
.open .dropdown-toggle.btn-u.btn-u-brown {
   background: #81674b;
}
.btn-u.btn-u-split-brown.dropdown-toggle {
   border-left: solid 1px #81674b;
}

.btn-u.btn-u-dark-blue {
   background: #4765a0;
}
.btn-u.btn-u-dark-blue:hover, 
.btn-u.btn-u-dark-blue:focus, 
.btn-u.btn-u-dark-blue:active, 
.btn-u.btn-u-dark-blue.active, 
.open .dropdown-toggle.btn-u.btn-u-dark-blue {
   background: #324c80;
}
.btn-u.btn-u-split-dark.dropdown-toggle {
   border-left: solid 1px #324c80;
}

.btn-u.btn-u-light-green {
   background: #79d5b3;
}
.btn-u.btn-u-light-green:hover, 
.btn-u.btn-u-light-green:focus, 
.btn-u.btn-u-light-green:active, 
.btn-u.btn-u-light-green.active, 
.open .dropdown-toggle.btn-u.btn-u-light-green {
   background: #59b795;
}
.btn-u.btn-u-split-light-green.dropdown-toggle {
   border-left: solid 1px #59b795;
}

.btn-u.btn-u-dark {
   background: #555;
}
.btn-u.btn-u-dark:hover, 
.btn-u.btn-u-dark:focus, 
.btn-u.btn-u-dark:active, 
.btn-u.btn-u-dark.active, 
.open .dropdown-toggle.btn-u.btn-u-dark {
   background: #333;
}
.btn-u.btn-u-split-dark.dropdown-toggle {
   border-left: solid 1px #333;
}

.btn-u.btn-u-light-grey {
   background: #585f69;
}
.btn-u.btn-u-light-grey:hover, 
.btn-u.btn-u-light-grey:focus, 
.btn-u.btn-u-light-grey:active, 
.btn-u.btn-u-light-grey.active, 
.open .dropdown-toggle.btn-u.btn-u-light-grey {
   background: #484f58;
}
.btn-u.btn-u-split-light-grey.dropdown-toggle {
   border-left: solid 1px #484f58;
}

/*Bordered Buttons*/
.btn-u.btn-brd {
  color: #555;
  /*font-weight: 200;*/
  background: none;
  padding: 5px 13px;
  border: solid 1px transparent;
   -webkit-transition: all 0.1s ease-in-out;
   -moz-transition: all 0.1s ease-in-out;
   -o-transition: all 0.1s ease-in-out;
   transition: all 0.1s ease-in-out;   
}
.btn-u.btn-brd:hover {
  background: none;
  border: solid 1px #eee;
}
.btn-u.btn-brd:focus {
  background: none;
}
.btn-u.btn-brd.btn-brd-hover:hover {
   color: #fff !important;
}

.btn-u.btn-brd {
  border-color: #72c02c;
}
.btn-u.btn-brd:hover {
  color: #5fb611;
  border-color: #5fb611;
}
.btn-u.btn-brd.btn-brd-hover:hover {
   background: #5fb611;   
}

.btn-u.btn-brd.btn-u-blue {
  border-color: #3498db;
}
.btn-u.btn-brd.btn-u-blue:hover {
  color: #2980b9;
  border-color: #2980b9;
}
.btn-u.btn-brd.btn-u-blue.btn-brd-hover:hover {
   background: #2980b9;
}

.btn-u.btn-brd.btn-u-red {
  border-color: #e74c3c;
}
.btn-u.btn-brd.btn-u-red:hover {
  color: #c0392b;
  border-color: #c0392b;
}
.btn-u.btn-brd.btn-u-red.btn-brd-hover:hover {
   background: #c0392b;
}

.btn-u.btn-brd.btn-u-orange {
  border-color: #e67e22;
}
.btn-u.btn-brd.btn-u-orange:hover {
  color: #d35400;
  border-color: #d35400;
}
.btn-u.btn-brd.btn-u-orange.btn-brd-hover:hover {
   background: #d35400;
}

.btn-u.btn-brd.btn-u-sea {
  border-color: #1abc9c;
}
.btn-u.btn-brd.btn-u-sea:hover {
  color: #16a085;
  border-color: #16a085;
}
.btn-u.btn-brd.btn-u-sea.btn-brd-hover:hover {
   background: #16a085;
}

.btn-u.btn-brd.btn-u-green {
  border-color: #2ecc71;
}
.btn-u.btn-brd.btn-u-green:hover {
  color: #27ae60;
  border-color: #27ae60;
}
.btn-u.btn-brd.btn-u-green.btn-brd-hover:hover {
   background: #27ae60;
}

.btn-u.btn-brd.btn-u-yellow {
  border-color: #f1c40f;
}
.btn-u.btn-brd.btn-u-yellow:hover {
  color: #f39c12;
  border-color: #f39c12;
}
.btn-u.btn-brd.btn-u-yellow.btn-brd-hover:hover {
   background: #f39c12;
}

.btn-u.btn-brd.btn-u-default {
  border-color: #95a5a6;
}
.btn-u.btn-brd.btn-u-default:hover {
  color: #7f8c8d;
  border-color: #7f8c8d;
}
.btn-u.btn-brd.btn-u-default.btn-brd-hover:hover {
   background: #7f8c8d;
}

.btn-u.btn-brd.btn-u-dark {
  border-color: #555;
}
.btn-u.btn-brd.btn-u-dark:hover {
  color: #333;
  border-color: #333;
}
.btn-u.btn-brd.btn-u-dark.btn-brd-hover:hover {
   background: #333;
}

.btn-u.btn-brd.btn-u-light-grey {
  border-color: #585f69;
}
.btn-u.btn-brd.btn-u-light-grey:hover {
  color: #484f58;
  border-color: #484f58;
}
.btn-u.btn-brd.btn-u-light-grey.btn-brd-hover:hover {
   background: #484f58;
}

.btn-u.btn-brd.btn-u-purple {
  border-color: #9b6bcc;
}
.btn-u.btn-brd.btn-u-purple:hover {
  color: #814fb5;
  border-color: #814fb5;
}
.btn-u.btn-brd.btn-u-purple.btn-brd-hover:hover {
   background: #814fb5;
}

.btn-u.btn-brd.btn-u-aqua {
  border-color: #27d7e7;
}
.btn-u.btn-brd.btn-u-aqua:hover {
  color: #26bac8;
  border-color: #26bac8;
}
.btn-u.btn-brd.btn-u-aqua.btn-brd-hover:hover {
   background: #26bac8;
}

.btn-u.btn-brd.btn-u-brown {
  border-color: #9c8061;
}
.btn-u.btn-brd.btn-u-brown:hover {
  color: #81674b;
  border-color: #81674b;
}
.btn-u.btn-brd.btn-u-brown.btn-brd-hover:hover {
   background: #81674b;
}

.btn-u.btn-brd.btn-u-dark-blue {
  border-color: #4765a0;
}
.btn-u.btn-brd.btn-u-dark-blue:hover {
  color: #324c80;
  border-color: #324c80;
}
.btn-u.btn-brd.btn-u-dark-blue.btn-brd-hover:hover {
   background: #324c80;
}

.btn-u.btn-brd.btn-u-light-green {
  border-color: #79d5b3;
}
.btn-u.btn-brd.btn-u-light-green:hover {
  color: #59b795;
  border-color: #59b795;
}
.btn-u.btn-brd.btn-u-light-green.btn-brd-hover:hover {
   background: #59b795;
}

.btn-u.btn-brd.btn-u-light {
  color: #fff;
  border-color: #fff;
}
.btn-u.btn-brd.btn-u-light:hover {
  border-color: #fff;
}
.btn-u.btn-brd.btn-u-light.btn-brd-hover:hover {
   background: #fff;
   color: #555 !important;
}

/*Dropdown Buttons
------------------------------------*/
.dropdown-show {
  box-shadow: 0 0 4px #eee;
  display: inline-block;
  position: relative;
}

/* cjo - added button space class
  for when buttons next to each other */
.btn-space {
  margin-right: 5px;
}