// Make sure the bootstrap-sass and lato fonts are resolved correctly
$icon-font-path: "~bootstrap-sass/assets/fonts/bootstrap/";
$fa-font-path: "../../../node_modules/font-awesome/fonts" !default;

// for original site, using bootstrap 3 sass, use below line
//@import "~bootstrap-sass/assets/stylesheets/bootstrap";

/* custom overrides 2023 */
$breadcrumb-divider-color: #cccccc;
$accordion-button-bg: #f5f5f5;
//$accordion-button-active-bg: #d6d6d6;
//$accordion-button-active-color: #212529;


/* navbar customisation */
$nav-link-font-size: 15px;
$nav-link-color: #687074;
$nav-link-font-weight: 400;

/* theme colors */
//$primary: #428bca;
$primary: #3576ae;



// for new bootstrap 5, use the below line
@import "~bootstrap/scss/bootstrap";

@import "~bootstrap-icons/font/bootstrap-icons.scss";

// Pikaday
@import './node_modules/pikaday/scss/pikaday';

//@import "base";
@import "header";
@import "footer";
@import "buttons";
@import "icons";
@import "heights";
@import "headings";
@import "lists";
@import "forms";
@import "task-calendar";
@import "pawa-form";
@import "stockdelivery-form";
@import "stockcollection-form";
@import "collection-schedule";
@import "login";
//@import "../vendor/font-awesome/scss/font-awesome.scss";
//@import "../vendor/bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css";
@import 'node_modules/font-awesome/scss/font-awesome.scss';
@import "~bootstrap-datepicker/dist/css/bootstrap-datepicker3";




/*
@import "events";
*/
/*   
 * Template Name: Unify - Responsive Bootstrap Template
 * Description: Business, Corporate, Portfolio, E-commerce, Blog and One Page Template.
 * Version: 1.8
 * Author: @htmlstream
 * Website: http://htmlstream.com
*/

/*Reset Styles
------------------------------------*/
* {
   border-radius: 0;
}

a,
a:focus, 
a:hover, 
a:active,
button,
button:hover {
   outline: 0 !important;
}

a:focus {
  text-decoration: none;
}

hr {
  margin: 30px 0;
}

hr.hr-xs {
  margin: 10px 0;
}

hr.hr-md {
  margin: 20px 0;
}

hr.hr-lg {
  margin: 40px 0;
}

/*Headings*/
h1 {
  font-size: 28px;
  line-height: 35px;
}
h2 {
  font-size: 24px;
  line-height: 33px;
}
h3 {
  font-size: 20px;
  line-height: 27px;
}
h4 {
  line-height: 25px;
}
h5 {
  line-height: 20px;
}
h6 {
  line-height: 18px;
}

h1, h2, h3, h4, h5, h6 {
  color: #555;    
  margin-top: 5px;
  text-shadow: none; 
  font-weight: normal; 
  font-family: "Open Sans", Arial, sans-serif;
} 

h1 i, h2 i, h3 i, h4 i, h5 i, h6 i {
   margin-right: 5px;
}



body {
    color: #333;
    font-size: 13px;
    line-height: 1.6;
}
.wrapper {
    background: #f3f3f4 none repeat scroll 0 0;
}

p, li, label {
  color: #555;
}

.buttonGroup {
  padding: 0px 2px 5px 0px;
}

.requiredIndicator {
  color: #ff0000;
  font-size: 16px;
}

form section {
  margin-bottom: 20px;
}

/* tighter to form field */
.help-block {
  margin-top: 1px;
}

/* ensure li gets the help-block color */
span.help-block li {
  color: #b94a48;
}

#formHeaderIntro {
  margin-bottom: 15px;
  color: #737373;
}

/*--------------------------------------------------
    [9. Call To Action]
----------------------------------------------------*/
/*Call To Action v1
------------------------------------*/
.call-action-v1 p {
  margin-top: 5px;
  font-size: 18px;
  font-weight: 200;
  margin-bottom: 0;
}

.call-action-v1.call-action-v1-boxed {
  padding: 0 25px;
}

.call-action-v1 .call-action-v1-box {
  width: 100%;
  display: table;
  padding: 30px 0;
  vertical-align: middle;
}

.call-action-v1 .call-action-v1-in {
  display: table-cell;
  vertical-align: middle;
}

.call-action-v1 .call-action-v1-in.inner-btn {
  padding-top: 1px;
  min-width: 250px;
  text-align: right;
}

@media (max-width: 992px) {
  .call-action-v1 .call-action-v1-in,
  .call-action-v1 .call-action-v1-in.inner-btn {
    display: block;
    text-align: center;
  }

  .call-action-v1 .call-action-v1-in p {
    margin-bottom: 20px;
  }
}

/* med settings */

.navbar {/* med224 */
	padding-top: 0px;
	padding-bottom: 0px;
}

a.logo {/* med224 */
	align-content: center;
	display: grid;
	margin-left: 0px !important;
}

.btn-info {/* med224 */
	color: #fff;
	margin-bottom: 1rem;
}

.btn-info:hover {/* med224 */
	color: #fff;
}

.button-row {/* med224 */
	padding-left: 0px;
}

a {/* med224 */
	text-decoration: none;
}

a:hover {/* med224 */
	text-decoration: underline;
}
a.btn:hover {/* med224 */
	text-decoration: none;
}
#medisortuser_delete {/* med224 */
	margin-top: 1rem;
}
#loginContainer .btn {/* med224 */
	margin-right: 0.5rem;
}
#tl_popupPOSDV {/* med224 prevents footer bar being wider than screen */
	max-width: -webkit-fill-available;
}

.btn-u {/* med224 */
	font-size: 1rem !important;
}

a.btn-u {/* med224 */
	vertical-align: middle !important;
}

.alert p {/* med224 */
	margin-bottom: 0px;
}