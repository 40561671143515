/*Column Sizes
------------------------------------*/
/*Remove the Gutter Padding from Columns*/
.no-gutter > [class*='col-'] {
  padding-right: 0;
  padding-left: 0;
}

.no-gutter.no-gutter-boxed {
  padding-right: 15px;
  padding-left: 15px;
}

/*Heights
------------------------------------*/
.height-100 { min-height: 100px;}
.height-150 { min-height: 150px;}
.height-200 { min-height: 200px;}
.height-250 { min-height: 250px;}
.height-300 { min-height: 300px;}
.height-350 { min-height: 350px;}
.height-400 { min-height: 400px;}
.height-450 { min-height: 450px;}
.height-500 { min-height: 500px !important;}

/*Spaces
------------------------------------*/
.no-padding {
  padding: 0 !important;
}

.no-margin {
  margin: 0;
}

.no-top-space {
  margin-top: 0 !important;
  padding-top: 0 !important;
}

.no-bottom-space {
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
}

.no-margin-bottom {
  margin-bottom: 0 !important;
}

.no-padding-bottom {
  padding-bottom: 0 !important;
}

.content-xs {
  padding-top: 20px;
  padding-bottom: 20px;
}

.content {
  padding-top: 40px;
  padding-bottom: 40px;
}

.content-sm {
  padding-top: 60px;
  padding-bottom: 60px;
}

.content-md {
  padding-top: 80px;
  padding-bottom: 80px;
}

.content-lg {
  padding-top: 100px;
  padding-bottom: 100px;
}

.space-lg-hor {
  padding-left: 60px;
  padding-right: 60px;
}

.space-xlg-hor {
  padding-left: 100px;
  padding-right: 100px;
}

.margin-bottom-5, 
.margin-bottom-10, 
.margin-bottom-15, 
.margin-bottom-20,
.margin-bottom-25, 
.margin-bottom-30, 
.margin-bottom-35, 
.margin-bottom-40, 
.margin-bottom-45, 
.margin-bottom-50, 
.margin-bottom-55, 
.margin-bottom-60,
.margin-bottom-100 { 
   clear:both;
}
.margin-bottom-5  { margin-bottom:5px;}
.margin-bottom-10 { margin-bottom:10px;} 
.margin-bottom-15 { margin-bottom:15px;}
.margin-bottom-20 { margin-bottom:20px;}
.margin-bottom-25 { margin-bottom:25px;}
.margin-bottom-30 { margin-bottom:30px;}
.margin-bottom-35 { margin-bottom:35px;}
.margin-bottom-40 { margin-bottom:40px;}
.margin-bottom-45 { margin-bottom:45px;}
.margin-bottom-50 { margin-bottom:50px;}
.margin-bottom-55 { margin-bottom:55px;}
.margin-bottom-60 { margin-bottom:60px;}
.margin-bottom-100 { margin-bottom:100px;}

@media (max-width: 768px) {
  .sm-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .sm-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .sm-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .sm-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .sm-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .sm-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

@media (max-width: 992px) {
  .md-margin-bottom-10 {
    margin-bottom: 10px;
  }
  .md-margin-bottom-20 {
    margin-bottom: 20px;
  }
  .md-margin-bottom-30 {
    margin-bottom: 30px;
  }
  .md-margin-bottom-40 {
    margin-bottom: 40px;
  }
  .md-margin-bottom-50 {
    margin-bottom: 50px;
  }
  .md-margin-bottom-60 {
    margin-bottom: 60px;
  }
}

/*Other Spaces*/
.margin-top-20 { margin-top: 20px;}
.margin-left-5 { margin-left: 5px;}
.margin-left-10 { margin-left: 10px;}
.margin-right-5 { margin-right: 5px;}
.margin-right-10 { margin-right: 10px;}

.padding-top-5 { padding-top: 5px;}
.padding-left-5 { padding-left: 5px;}
