/*Block Headline*/
.headline {
  display: block;
  margin: 10px 0 25px 0;
  border-bottom: 1px dotted #e4e9f0;
}

.headline h2 {
  font-size: 22px;
}

.headline h2, 
.headline h3, 
.headline h4 {
  margin: 0 0 -2px 0;
  padding-bottom: 5px;
  display: inline-block;
  border-bottom: 2px solid #72c02c;
}

.headline-md {
  margin-bottom: 15px;
}

.headline-md h2 {
  font-size: 21px;
}

/*Heading Options*/
.heading {
  text-align: center;
}

.heading h2 {
  padding: 0 12px;
  position: relative;
  display: inline-block;
  line-height: 34px !important; /*For Tagline Boxes*/
}

.heading h2:before, 
.heading h2:after {
  content: ' ';
  width: 70%;  
  position: absolute;
  border-width: 1px;
  border-color: #bbb;
}

.heading h2:before {
  right: 100%;
}

.heading h2:after {
  left: 100%;
}

@media (max-width: 768px) {
  .heading h2:before, 
  .heading h2:after {
    width: 20%;  
  }
}
