.shortcuts li {
  margin-bottom: 6px;
}
.shortcuts li a {
  text-decoration: none;
}
.shortcuts li i {
  color: #72c02c;
  font-size: 13px;
  margin-right: 8px;
  min-width: 15px;
  position: relative;
  text-align: center;
}
.shortcuts li:hover i, .shortcuts li:hover a {
  color: #777;
}
.shortcuts li:hover a:hover {
  text-decoration: none;
}