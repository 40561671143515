.pawaForm table tbody tr td, .pawaForm table thead tr th {
  vertical-align: middle;
}

.pawaForm table .form-group {
  margin-bottom: 0px;
}

.pawaForm input.tickIfUsed {
  float: none;
}