/**
 * Imported from layout.scss
 *
 * Because this starts with an _, it's a partial, and won't be (and isn't
 * meant to be) compiled to a CSS file. Instead, CSS in here will be inside
 * the layout.css file. This is in its own file purely for convenience.
 */

/* cjo customisation */
.header span {
	color: #7c8082;
	font-size: 11px;
	text-transform: uppercase;
}


/*Top Bar (login, search etc.)
------------------------------------*/
header {
    /* height: 90px; */
}
header .topbar {
	z-index: 12;
	padding: 8px 0;
	position: relative;
}

header .topbar a {
	text-decoration: none;
}

@media (max-width: 991px) {
	header .topbar {
		margin-bottom: 20px;
	}
}

header .topbar ul.loginbar {
	margin: 0;
}

header .topbar ul.loginbar > li {
	display: inline;
	list-style: none;
	position: relative;
	padding-bottom: 15px;
}

header .topbar ul.loginbar > li > a, 
header .topbar ul.loginbar > li > a:hover {
	color: #7c8082;
	font-size: 11px;
	text-transform: uppercase;
}

header .topbar ul.loginbar li i.fa { 
	color: #bbb;
}

header .topbar ul.loginbar li.topbar-devider { 
	top: -1px;
	padding: 0;
	font-size: 8px;
	position: relative;
	margin: 0 9px 0 5px;
	font-family: Tahoma;
	border-right: solid 1px #bbb;
}

/*No Topbar*/
header.no-topbar .navbar-toggle {
	margin-top: 25px;
}

/*--------------------------------------------------
    [2. Header - Navbar]
----------------------------------------------------*/

/*Navbar*/
.header {
	z-index: 99;
	background: #fff;
	position: relative;
	border-bottom: solid 2px #eee;
}

/*Header Container*/
.header > .container {
	display: table;
	margin-bottom: -40px;
}

@media (max-width: 768px) {
	.header > .container {
		width: 100%;
	}
}

@media (max-width: 991px) {
	.header > .container {
		margin-bottom: 0;
	}
}

/*Logo*/
.header .logo {
	height: 100%;
	min-width: 100px;
	display: table-cell;
	vertical-align: middle;
}

.header .logo img {
	z-index: 1;
	margin: 10px 0;
	position: relative;
}

/*Big Logo Style*/
@media (max-width: 1200px) {
	.header img.big-logo {
		width: 120px;
		height: auto;
	}
}

/*Navbar Toggle*/
.header .navbar-toggle {
	border-color: #5fb611;
}

@media (max-width: 991px) {
	.header .navbar-toggle {
		margin-right: 0;
	}
}

.header .navbar-toggle .fa {
	color: #fff;
	font-size: 19px;
}

.header .navbar-toggle,
.header .navbar-toggle:hover, 
.header .navbar-toggle:focus {
	background: #72c02c;
	padding: 6px 10px 2px;
}

.header .navbar-toggle:hover {
	background: #5fb611 !important;
}

/*Navbar Collapse*/
.header .navbar-collapse {
	position: relative;
}

/*Navbar Collapse*/
@media (max-width: 991px) {
  	.header .navbar-collapse,
	.header .navbar-collapse .container {
		padding-left: 0;
		padding-right: 0;
	}
}

/*Navbar Menu*/
.header .navbar-nav > li > a {
	color: #687074;
	font-size: 15px;
	font-weight:400;
	text-transform: uppercase;
}

.header .navbar-nav > .active > a {
	color: #72c02c;
}

@media (min-width: 992px) {
	.header .navbar-nav {
		float: right;
	}	
}

/*--------------------------------------------------
    [3. Header - Responsive Navbar Style]
----------------------------------------------------*/

/*Responsive Navbar*/
@media (max-width: 991px) {
	/*Responsive code for max-width: 991px*/
    .header .navbar-header {
        float: none;
    }
    
    .header .navbar-toggle {
        display: block;
    }
    
    .header .navbar-collapse.collapse {
        display: none !important;
    }
    
    .header .navbar-collapse.collapse.in {
        display: block !important;
  		overflow-y: auto !important;
    }
    
    .header .navbar-nav {
        margin: 0 0 5px;
        float: none !important;
    }

    .header .navbar-nav > li {
        float: none;
    }
    
    .header .navbar-nav > li > a {
        padding-top: 30px;
        padding-bottom: 40px;
    }

    /*Pull Right*/
    .header .dropdown-menu.pull-right {
		float: none !important;
	}

	/*Dropdown Menu Slide Down Effect*/
	.header .navbar-nav .open .dropdown-menu {
		border: 0;
		float: none;
		width: auto;
		margin-top: 0;
		position: static;
		box-shadow: none;
		background-color: transparent;
	}

	.header .navbar-nav .open > a,
	.header .navbar-nav .open > a:hover,
	.header .navbar-nav .open > a:focus {
		border-bottom-color: #eee;
	}

	.header .navbar-nav .open .dropdown-menu > li > a,
	.header .navbar-nav .open .dropdown-menu .dropdown-header {
		padding: 5px 15px 5px 25px;
	}
	
	.header .navbar-nav .open .dropdown-menu > li > a {
		line-height: 20px;
	}
	
	.header .navbar-nav .open .dropdown-menu > li > a:hover,
	.header .navbar-nav .open .dropdown-menu > li > a:focus {
		background-image: none;
	}

	.header .navbar-nav .open .dropdown-menu > li > a {
		color: #777;
	}

	.header .navbar-nav .open .dropdown-menu > li > a:hover,
	.header .navbar-nav .open .dropdown-menu > li > a:focus {
		color: #333;
		background-color: transparent;
	}
	
	.header .navbar-nav .open .dropdown-menu > .active > a,
	.header .navbar-nav .open .dropdown-menu > .active > a:hover,
	.header .navbar-nav .open .dropdown-menu > .active > a:focus {
		color: #555;
		background-color: #e7e7e7;
	}
	
	.header .navbar-nav .open .dropdown-menu > .disabled > a,
	.header .navbar-nav .open .dropdown-menu > .disabled > a:hover,
	.header .navbar-nav .open .dropdown-menu > .disabled > a:focus {
		color: #ccc;
		background-color: transparent;
	}

	.header .dropdown-menu.no-bottom-space {
		padding-bottom: 0;
	}  	

	/*Design for max-width: 991px*/
	.header .navbar-nav > li > a {
		font-size: 14px;
		padding: 9px 10px;
	}

	.header .navbar-nav > li a {
		border-bottom: solid 1px #eee;
	}	

	.header .navbar-nav > li > a:focus {
		background: none;
	}

	.header .navbar-nav > li > a:hover {
		color: #72c02c;
	}

	.header .navbar-nav > .active > a,
	.header .navbar-nav > .active > a:hover,
	.header .navbar-nav > .active > a:focus {
		background: #72c02c;
		color: #fff !important;	
	}

	.header .dropdown .dropdown-submenu > a { 
		font-size: 13px;
		color: #555 !important;
		text-transform: uppercase;
	}
}

@media (min-width: 992px) {
	/*Navbar Collapse*/
	.header .navbar-collapse {
		padding: 0;
	}

	/*Navbar*/
	.header .container > .navbar-header, 
	.header .container-fluid > .navbar-header, 
	.header .container > .navbar-collapse, 
	.header .container-fluid > .navbar-collapse {
		margin-bottom: -10px;
	}

	.header .navbar-nav {
		position: relative;
	}

	.header .navbar-nav > li > a {
		bottom: -2px;
		position: relative;
		padding: 9px 30px 9px 20px;
	}

	.header .navbar-nav > li > a,
	.header .navbar-nav > li > a:focus {	
		border-bottom: solid 2px transparent;
	}

	.header .navbar-nav > li > a:hover,
	.header .navbar-nav > .active > a {
		bottom: -2px;
		position: relative;
		border-bottom: solid 2px #72c02c;
	}
	
	.header .navbar-nav > li > a,
	.header .navbar-nav > li > a:hover,
	.header .navbar-nav > li > a:focus,	
	.header .navbar-nav > .active > a, 
	.header .navbar-nav > .active > a:hover, 
	.header .navbar-nav > .active > a:focus {
		background: none;
	}

	.header .navbar-nav > .open > a,
	.header .navbar-nav > .open > a:hover,
	.header .navbar-nav > .open > a:focus {
		color: #72c02c;
	}

	.header .navbar-nav > li:hover > a {
        color: #72c02c;
    }

	/*Dropdown Menu*/
	.header .dropdown-menu { 
		padding: 0;
		border: none;
		min-width: 200px;
		border-radius: 0; 
		z-index: 9999 !important;
		border-top: solid 2px #72c02c; 
		border-bottom: solid 2px #687074; 
	}

	.header .dropdown-menu li a { 
		color: #687074; 
		font-size: 13px; 
		font-weight: 400; 
		padding: 6px 15px; 
		border-bottom: solid 1px #eee;
	}

	.header .dropdown-menu .active > a,
	.header .dropdown-menu li > a:hover {
		color: #687074;
		filter: none !important;
		background: #eee !important;
		-webkit-transition: all 0.1s ease-in-out;
		-moz-transition: all 0.1s ease-in-out;
		-o-transition: all 0.1s ease-in-out;
		transition: all 0.1s ease-in-out;
	}

	.header .dropdown-menu li > a:focus {
		background: none;
		filter: none !important;
	}

	.header .navbar-nav > li.dropdown:hover > .dropdown-menu {
		display: block;
	}	

	.header .open > .dropdown-menu {
		display: none;
	}

	/*Search*/
	.header .navbar-nav .search-open {
		width: 330px;
	}
}

/*--------------------------------------------------
    [4. Header - Submenu]
----------------------------------------------------*/

/*Dropdown Submenu for BS3
------------------------------------*/
.header .dropdown-submenu { 
   position: relative; 
}

.header .dropdown > a:after,
.header .dropdown-submenu > a:after {
    top: 8px;
    right: 9px;
    font-size: 11px;
    content: "\f105";
    position: absolute;
    font-weight: normal;
    display: inline-block;
    font-family: FontAwesome;
}

@media (max-width: 991px) {
  	.header .dropdown-submenu > a:after {
      	content: " ";
  	}
}

.header .dropdown > a:after {
    top: 9px;
    right: 15px;
    content: "\f107";
}

.header .dropdown-submenu > .dropdown-menu { 
	top: 3px; 
	left: 100%; 
	margin-top: -5px; 
	margin-left: 0px; 
}

/*Submenu comes from LEFT side*/
.header .dropdown-submenu > .dropdown-menu.submenu-left {
	left: -100%;
}

.header .dropdown-submenu:hover > .dropdown-menu {  
   	display: block;
}

@media (max-width: 991px) {
	.header .dropdown-submenu > .dropdown-menu {  
		display: block;
		margin-left: 15px;
	}
}

.header .dropdown-submenu.pull-left {
	float: none;
}

.header .dropdown-submenu.pull-left > .dropdown-menu {
	left: -100%;
	margin-left: 10px;
}

/*Dropdow Mmenu Icons (Font Awesome)*/
.header .dropdown-menu li [class^="fa-"],
.header .dropdown-menu li [class*=" fa-"] {
	left: -3px;
	width: 1.25em;
	margin-right: 1px;
	position: relative;
	text-align: center;
	display: inline-block;
}
.header .dropdown-menu li [class^="fa-"].fa-lg,
.header .dropdown-menu li [class*=" fa-"].fa-lg {
	/* increased font size for fa-lg */
	width: 1.5625em;
}

/* new styles 2023 */

.page-title {
	background-color: #fdfdfd;
	border-bottom: solid 1px #eeeeee;

	h1 {
		line-height: 35px;
		margin-bottom: 0px;
		font-size: 1.5rem;
	}
}

.header {
	background: #fff;
	border-bottom: solid 2px #eee;
}

.breadrumb {
	padding-top: 10px;
}

.breadcrumb-item.active,
.breadcrumb-item a:hover {
	color: #72c02c;
	text-decoration: none;
}

.breadcrumb-item a {
	color: #777;
	text-decoration: none;
}
