/*Font Awesome Icon Styles*/
i.icon-custom {
  color: #555;
  width: 40px;
  height: 40px;
  font-size: 20px;
  line-height: 40px;
  margin-bottom: 5px;
  text-align: center;
  display: inline-block;   
  border: solid 1px #555;
}

i.icon-sm {
   width: 35px;
   height: 35px;
   font-size: 16px;
   line-height: 35px;
}

i.icon-md {
   width: 55px;
   height: 55px;
   font-size: 22px;
   line-height: 55px;
}

i.icon-lg {
  width: 60px;
  height: 60px;
  font-size: 31px;
  line-height: 60px;
  margin-bottom: 10px;
}

i.icon-2x {
  font-size: 30px;
}
i.icon-3x {
  font-size: 40px;
}
i.icon-4x {
  font-size: 50px;
}

/*Line Icons*/
i.icon-line {
  font-size: 17px;
}
i.icon-sm.icon-line {
  font-size: 14px;
}
i.icon-md.icon-line {
  font-size: 22px;
}
i.icon-lg.icon-line {
  font-size: 28px;
}

i.icon-2x.icon-line {
  font-size: 27px;
}
i.icon-3x.icon-line {
  font-size: 36px;
}
i.icon-4x.icon-line {
  font-size: 47px;
}

/*Icon Styles For Links*/
.link-icon,
.link-bg-icon {
  color: #555;  
}

.link-icon:hover,
.link-bg-icon:hover {
  border: none;  
  text-decoration: none;
}

.link-icon:hover i {
  color: #72c02c;
  background: none;
  border: solid 1px #72c02c;
}

.link-bg-icon:hover i {
  color: #72c02c;
  background: #72c02c;
  border-color: #72c02c;
  color: #fff !important;
}

/*Icons Color*/
i.icon-color-u,
i.icon-color-red,
i.icon-color-sea,
i.icon-color-dark,
i.icon-color-grey,
i.icon-color-blue,
i.icon-color-green,
i.icon-color-yellow,
i.icon-color-orange,
i.icon-color-purple,
i.icon-color-aqua,
i.icon-color-brown,
i.icon-color-dark-blue,
i.icon-color-light-grey,
i.icon-color-light-green, {
  background: none;
}

i.icon-color-u {
   color: #72c02c;
   border: solid 1px #72c02c;
}
i.icon-color-blue {
   color: #3498db;
   border: solid 1px #3498db;
}
i.icon-color-red {
   color: #e74c3c;
   border: solid 1px #e74c3c;
}
i.icon-color-sea {
   color: #1abc9c;
   border: solid 1px #1abc9c;
}
i.icon-color-green {
   color: #2ecc71;
   border: solid 1px #2ecc71;
}
i.icon-color-yellow {
   color: #f1c40f;
   border: solid 1px #f1c40f;
}
i.icon-color-orange {
   color: #e67e22;
   border: solid 1px #e67e22;
}
i.icon-color-grey {
   color: #95a5a6;
   border: solid 1px #95a5a6;
}
i.icon-color-purple {
   color: #9b6bcc;
   border: solid 1px #9b6bcc;
}
i.icon-color-aqua {
   color: #27d7e7;
   border: solid 1px #27d7e7;
}
i.icon-color-brown {
   color: #9c8061;
   border: solid 1px #9c8061;
}
i.icon-color-dark-blue {
   color: #4765a0;
   border: solid 1px #4765a0;
}
i.icon-color-light-green {
   color: #79d5b3;
   border: solid 1px #79d5b3;
}
i.icon-color-light {
   color: #fff;
   border: solid 1px #fff;
}
i.icon-color-light-grey {
   color: #585f69;
   border: solid 1px #585f69;
}

/*Icons Backgroun Color*/
i.icon-bg-u,
i.icon-bg-red,
i.icon-bg-sea,
i.icon-bg-dark,
i.icon-bg-darker,
i.icon-bg-grey,
i.icon-bg-blue,
i.icon-bg-green,
i.icon-bg-yellow,
i.icon-bg-orange,
i.icon-bg-purple,
i.icon-bg-aqua,
i.icon-bg-brown,
i.icon-bg-dark-blue,
i.icon-bg-light-grey,
i.icon-bg-light-green {
   color: #fff;
   border-color: transparent;
}

i.icon-bg-u {
   background: #72c02c;
}
i.icon-bg-blue {
   background: #3498db;
}
i.icon-bg-red {
   background: #e74c3c;
}
i.icon-bg-sea {
   background: #1abc9c;
}
i.icon-bg-green {
   background: #2ecc71;
}
i.icon-bg-yellow {
   background: #f1c40f;
}
i.icon-bg-orange {
   background: #e67e22;
}
i.icon-bg-grey {
   background: #95a5a6;
}
i.icon-bg-dark {
   background: #555;
}
i.icon-bg-darker {
   background: #333;
}
i.icon-bg-purple {
   background: #9b6bcc;
}
i.icon-bg-aqua {
   background: #27d7e7;
}
i.icon-bg-brown {
   background: #9c8061;
}
i.icon-bg-dark-blue {
   background: #4765a0;
}
i.icon-bg-light-green {
   background: #79d5b3;
}
i.icon-bg-light {
   background: #fff;
   border-color: transparent;   
}
i.icon-bg-light-grey {
   background: #585f69;
   border-color: transparent;  
}

/* Make Font Awesome icons fixed width */
.fa-fixed [class^="fa"],
.fa-fixed [class*=" fa"] {
  width: 1.25em;
  text-align: center;
  display: inline-block;
}
.fa-fixed [class^="fa"].fa-lg,
.fa-fixed [class*=" fa"].fa-lg {
  /* increased font size for fa-lg */
  width: 1.5625em;
}

