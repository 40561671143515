.taskCalendarMonthHeading {
  background-color: #f3f3f4;
  padding: 5px 5px 0px 5px;
  border: 1px solid #2C2C2C;
}

.taskCalendarMonthContainer {
  border: 1px solid #2C2C2C;
  padding: 3px;
  margin-bottom: 30px;
}

table#taskCalendar td, table#taskCalendar th {
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
}

table#taskCalendar .hasEvent {
/*  border: 1px solid #2C2C2C;*/
  border: 1px double #2C2C2C;/* med224 */
  background-color: #f3f3f4;
}